import { defineStore } from "pinia";
import { ref, computed } from "vue";
import ApiService from "../common/service.api";

export const useGeneralStore = defineStore("general", () => {
  const loading = ref({
    userList: false,
  });

  const _youbeFilter = ref({
    regione: "all",
    provincia: "all",
    citta: "all",
    agente: null,
  });
  const _userList = ref([]);

  const _selectedUser = ref(null);

  const _currentSurvey = ref(null);

  const youbeFilter = computed(() => {
    return _youbeFilter.value;
  });

  const userList = computed(() => {
    if (!_userList.value.length === 0) getUsers();
    return _userList.value;
  });

  const selectedUser = computed(() => {
    return _selectedUser.value;
  });

  const currentSurvey = computed(() => {
    return _currentSurvey.value;
  });

  function setYoubeFilter(filter) {
    _youbeFilter.value = filter;
  }

  function resetGeneralFilter() {
    _youbeFilter.value = {
      regione: "all",
      provincia: "all",
      citta: "all",
      agente: null,
    };
  }

  async function getUsers() {
    // loading.value.userList = true;
    try {
      const res = await ApiService.get("User");
      _userList.value = res.data;
    } catch (error) {
      console.error("ERR - pinia - getUsers: ", error);
    } finally {
      // loading.value.userList = false;
    }
  }

  function setSelectedUser(user) {
    _selectedUser.value = user;
  }

  function setCurrentSurvey(survey) {
    _currentSurvey.value = survey;
  }

  return {
    loading,
    youbeFilter,
    setYoubeFilter,
    resetGeneralFilter,
    userList,
    getUsers,
    selectedUser,
    setSelectedUser,
    currentSurvey,
    setCurrentSurvey,
  };
});
